import smoothscroll from "smoothscroll-polyfill";
import WEBGL from "three/examples/jsm/capabilities/WebGL";
import {
  Scene,
  PerspectiveCamera,
  WebGLRenderer,
  Mesh,
  Group,
  MeshStandardMaterial,
  RectAreaLight,
  PlaneGeometry,
  TextureLoader,
  BoxGeometry,
  DoubleSide,
  Color,
  // AxesHelper,
} from "three";

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { RectAreaLightUniformsLib } from "three/examples/jsm/lights/RectAreaLightUniformsLib";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

const isTouchDevice =
  "ontouchstart" in window ||
  navigator.maxTouchPoints > 0 ||
  navigator.msMaxTouchPoints > 0;

var controls = 0,
  camera = 0,
  d = document,
  w = window,
  shard = 0,
  print = 0,
  pi = Math.PI,
  queueSwap = 0,
  ih,
  iw,
  sh,
  sw;

w.page = 0;

smoothscroll.polyfill();

d.querySelector("#sd").addEventListener("click", function () {
  w.scroll({
    top: d.querySelector("main").clientHeight - w.innerHeight,
    left: 0,
    behavior: "smooth",
  });
});

if (d.querySelector("select")) {
  d.querySelector("select").onchange = function (e) {
    d.querySelector('[type="submit"]').classList =
      e.target.value != "0" || this.value != "0" ? "" : "hd";
  };
}

let resize = function () {
  units();
};
units();

const isWebGLAvailable = WEBGL.isWebGLAvailable();
isWebGLAvailable ? init() : fallback();

function init() {
  RectAreaLightUniformsLib.init();
  const scene = new Scene();
  camera = new PerspectiveCamera(30, iw / ih, 0.1, 7);

  const loader = new TextureLoader();
  const renderer = new WebGLRenderer({
    alpha: true,
    antialias: true,
    powerPreference: "high-performance",
  });

  renderer.setPixelRatio(1.6);
  // renderer.setClearColor(0xffffff);
  document.body.appendChild(renderer.domElement);

  resize = function () {
    units();
    camera.aspect = (w.page ? sw : iw) / (w.page ? sh : ih);
    camera.updateProjectionMatrix();
    renderer.setSize(w.page ? sw : iw, w.page ? sh : ih);
  };
  resize();

  renderer.domElement.addEventListener("pointerdown", function (e) {
    e.target.mv = 0;
    e.target.x = e.pageX;
    e.target.y = e.pageY;
    e.target.addEventListener("pointermove", pointermove);
  });

  renderer.domElement.addEventListener("pointerup", pointerup, false);

  const rect = new PlaneGeometry(1, 1);
  const baseMaterial = new MeshStandardMaterial({
    color: 0xbebcb0,
  });

  const mainTex = loader.load(
    "https://i0.wp.com/willgates.com/2021-will-gates-20-21-framed-01.jpg?w=1200"
  );

  const frontMaterial = new MeshStandardMaterial({
    color: 0xffffff,
    roughness: 0.25,
    map: mainTex,
  });

  const frontGlossMaterial = new MeshStandardMaterial({
    color: 0xffffff,
    roughness: 0.05,
    transparent: true,
    alphaMap: loader.load("https://i0.wp.com/willgates.com/main-alpha.jpeg"),
    map: mainTex,
  });

  let width = 1;
  let height = width * 1.3575;
  let depth = 0.005;

  print = new Group();
  [
    [width, height, ["z", depth / 2], 0, frontMaterial],
    [width, height, ["z", depth / 2 + 0.0001], 0, frontGlossMaterial],
    [depth, height, ["x", -width / 2], ["y", -pi / 2]],
    [depth, height, ["x", width / 2], ["y", pi / 2]],
    [width, depth, ["y", height / 2], ["x", -pi / 2]],
    [width, depth, ["y", -height / 2], ["x", pi / 2]],
  ].forEach(function (vals) {
    const part = new Mesh(rect, vals[4] ? vals[4] : baseMaterial);
    part.scale.set(vals[0], vals[1], 1);
    if (vals[2]) part.position[vals[2][0]] = vals[2][1];
    if (vals[2]) part.rotation[vals[3][0]] = vals[3][1];
    print.add(part);
  });

  scene.add(print);

  var lightHolder = new Group();

  controls = new OrbitControls(camera, renderer.domElement);
  controls.enableZoom = false;
  controls.enableDamping = true;
  controls.enablePan = false;
  controls.autoRotateSpeed = 6;
  controls.enabled = isTouchDevice;
  controlRotation(0);

  if (isTouchDevice) {
    camera.position.z = 4;
  } else {
    camera.position.z = 4;
    var mouseX = 0,
      mouseY = 0,
      xPos = 0,
      yPos = 0,
      dX = 0,
      dY = 0;

    var marginX = 250;
    var marginY = 175;

    w.addEventListener("pointermove", function setMousePosition(e) {
      mouseX =
        (pi / 2 - 0.02) *
        (Math.min(1, Math.max(0, (e.clientX - marginX) / (iw - marginX * 2))) *
          2 -
          1);
      mouseY =
        (pi / 2 - 0.75) *
        (Math.min(1, Math.max(0, (e.clientY - marginY) / (ih - marginY * 2))) *
          2 -
          1);
    });
  }

  camera.lookAt(0, 0, 0);

  var lightHolder = new Group();
  [
    [6, 1, 5],
    [-6, -1, 5],
  ].forEach(function (p) {
    const light = new RectAreaLight(0xffffff, 2, 6, 2000);
    light.position.set(p[0], p[1], p[2]);
    light.lookAt(0, 0, 0);
    lightHolder.add(light);
  });
  scene.add(lightHolder);

  const gltfLoader = new GLTFLoader();

  gltfLoader.load(
    // resource URL
    "./shard-02.gltf",
    // called when the resource is loaded
    function (gltf) {
      gltf.scene.position.set(0, 0, 0);
      // gltf.scene.scale.set(0.075, 0.075, 0.075);
      gltf.scene.scale.set(0.0075, 0.0075, 0.0075);
      scene.add(gltf.scene);

      const shardMaterial = new MeshStandardMaterial({
        // color: 0x9da79f,
        color: 0xffffff,
        roughness: 0.05,
        transparent: true,
        // alphaTest: 1,
        // depthTest: false,
        opacity: 0.85,
        map: loader.load(
          "https://i1.wp.com/willgates.com/Shard_Model_V2_nolines_2.jpg"
        ),
      });

      shardMaterial.map.flipY = false;
      // shardMaterial.alphaMap.flipY = false;

      gltf.scene.children[0].children[0].material = shardMaterial;

      // console.log(gltf.scene);

      shard = gltf.scene;
      shard.visible = false;
    },
    // called while loading is progressing
    function (xhr) {
      // console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
    },
    // called when loading has errors
    function (error) {
      console.log(error);
    }
  );

  // const axesHelper = new AxesHelper(5);
  // scene.add(axesHelper);

  const ff = new Color(0xffffff);
  const f8 = new Color(0xf8f8f8);

  function animate() {
    requestAnimationFrame(animate);
    lightHolder.quaternion.copy(camera.quaternion);
    if (isTouchDevice || w.page) {
      controls && controls.update();
    } else {
      dX = mouseX - xPos;
      dY = mouseY - yPos;
      xPos += dX / 10;
      yPos += dY / 10;
      print.rotation.set(yPos, xPos, 0);
      // console.log(xPos / (pi / 2 - 0.02));

      var val = xPos / (pi / 2 - 0.02);

      if (val < -0.75 || val > 0.75) {
        frontGlossMaterial.roughness = 1;
        frontGlossMaterial.color = f8;
        frontMaterial.roughness = 1;
        frontMaterial.color = f8;
      } else {
        frontGlossMaterial.roughness = 0.05;
        frontGlossMaterial.color = ff;
        frontMaterial.roughness = 0.25;
        frontMaterial.color = ff;
      }
    }
    if (queueSwap) {
      queueSwap = 0;
      if (camera) {
        if (w.page) {
          camera.position.set(0, 0, 4);
          controls.enabled = true;
          controlRotation(1);
          print.visible = false;
          shard.visible = true;
        } else {
          camera.position.set(0, 0, 4);
          camera.lookAt(0, 0, 0);
          controls.enabled = isTouchDevice;
          controlRotation(0);
          print.visible = true;
          shard.visible = false;
        }
        camera.lookAt(0, 0, 0);
      }

      isWebGLAvailable && resize && resize();
      document.body.classList = w.page ? "ii" : "";
    }
    renderer.render(scene, camera);
  }
  animate();
  w.addEventListener("resize", resize, false);
}

function units() {
  iw = w.innerWidth;
  ih = w.innerHeight;
  sw = iw > 850 ? 200 : 150;
  sh = sw * 1.5;
}

function fallback() {
  console.log("fallback");
}

function pointermove(e) {
  e.target.mv =
    e.target.mv +
    Math.abs(e.target.x - e.pageX) +
    Math.abs(e.target.y - e.pageY);
}

function swap() {
  w.page = w.page ? 0 : 1;
  queueSwap = 1;
}

function pointerup(e) {
  if (e) {
    e.target.removeEventListener("pointermove", pointermove);
    if (e.target.mv < 5) {
      swap();
    }
  }
}

var controlRotationTo;
function controlRotation(set) {
  controls.minPolarAngle = set ? 0 : 0.75;
  controls.maxPolarAngle = set ? pi : pi - 0.75;
  controls.maxAzimuthAngle = set ? Infinity : pi / 2 - 0.02;
  controls.minAzimuthAngle = set ? Infinity : 0.02 - pi / 2;
  controls.autoRotate = set ? true : false;
  clearTimeout(controlRotationTo);
  setTimeout(function () {
    controls.autoRotate = set ? true : false;
  }, 100);
}

var swiper = d.querySelector("#sw");
var sc = d.querySelector("#sc");

swiper.rt = swiper.getBoundingClientRect();

function swdown(e) {
  e.target.s = e.clientX - swiper.rt.left;
  e.target.x = 0;
  swiper.addEventListener("pointermove", swmove);
}

function swmove(e) {
  e.target.x = e.target.s - (e.clientX - swiper.rt.left);
}

function swup(e) {
  swiper.removeEventListener("pointermove", swmove);
  sws(
    e.target.x
      ? e.target.x > 0
      : swiper.offsetWidth / 2 < e.clientX - swiper.rt.left
  );
}

function sws(m, c, n) {
  c = swiper.querySelector(".ac");
  n = m ? c.nextElementSibling : c.previousElementSibling;
  if (!n) {
    n = swiper.querySelector((m ? ":first" : ":last") + "-child");
  }
  c.classList = "";
  n.classList = "ac";
  sc.innerHTML = index(n);
}

swiper.addEventListener("pointerdown", swdown);
swiper.addEventListener("pointerup", swup);

function index(el) {
  if (!el) return -1;
  var i = 0;
  do {
    i++;
  } while ((el = el.previousElementSibling));
  return i;
}
